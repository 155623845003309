var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.renderKey,style:({
    backgroundColor: _vm.isApproved
      ? _vm.dailyReservationsThemeSettings.themeSettings.backgroundColor
      : '#ffffff',
    height: '100%',
    overflow: 'hidden auto'
  })},[_c('div',{ref:"header",staticClass:"main-header",style:({
      backgroundColor:
        _vm.dailyReservationsThemeSettings.themeSettings.titleBarColor
    })},[_c('b-row',{staticStyle:{"width":"100%","margin":"0","align-items":"center","padding-left":"1em"},attrs:{"align":"center"}},[_c('b-col',{staticClass:"col-3 text-left"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"220px"},attrs:{"src":_vm.dailyReservationsThemeSettings.logoUrl}})]),(_vm.totalPages > 1)?_c('b-col',{staticClass:"col-9 text-left"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('p',{style:({
              color:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
              paddingRight: '1em'
            })},[_vm._v(" "+_vm._s(_vm.$t('page'))+" ")]),_c('Pagination',{attrs:{"current-page":_vm.currentPage,"total-pages":_vm.totalPages,"active-item-color":_vm.dailyReservationsThemeSettings.themeSettings
                .regularTextColor,"disable-arrows":""}})],1)]):_vm._e()],1)],1),_c('div',[(!_vm.isApproved)?_c('div',{staticClass:"page-container",style:({
        paddingTop: '100px',
        paddingBottom: _vm.footerHeight
      })},[_c('b-container',{staticClass:"mt-4",attrs:{"align":"center"}},[_c('b-row',{attrs:{"align":"center"}},[_c('b-col',{staticClass:"col-3"}),_c('b-col',{staticClass:"col-6"},[(_vm.token)?_c('div',[_c('label',{style:({
                  fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
                })},[_vm._v(_vm._s(_vm.$t('tokenGenerated')))]),_c('h1',[_vm._v(_vm._s(_vm.token))])]):_vm._e()])],1)],1)],1):_vm._e(),(_vm.layout === 'table')?_c('TodayReservationsTable',{attrs:{"reservations":_vm.reservations,"daily-reservations-theme-settings":_vm.dailyReservationsThemeSettings,"show-desks":_vm.showDesks,"is-approved":_vm.isApproved,"footer-height":_vm.footerHeight}}):_vm._e(),(_vm.layout === 'cards')?_c('TodayReservationCards',{ref:"reservationCards",attrs:{"reservations":_vm.reservations,"show-desks":_vm.showDesks,"daily-reservations-theme-settings":_vm.dailyReservationsThemeSettings,"is-approved":_vm.isApproved,"footer-height":_vm.footerHeight,"header-height":_vm.headerHeight},on:{"resize":function (maxReservationsPerPage) {_vm.perPage = maxReservationsPerPage}}}):_vm._e()],1),_c('div',{ref:"footer",staticClass:"footer main-footer",style:({
      'background-color':
        _vm.dailyReservationsThemeSettings.themeSettings.titleBarColor
    })},[_c('b-row',[_c('b-col',{staticClass:"col-8 mt-3 pl-4",attrs:{"align":"left"}},[_c('h3',{style:({
            color:
              _vm.dailyReservationsThemeSettings.themeSettings
                .regularTextColor,
            fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
            paddingLeft: '0.5em'
          })},[_vm._v(" "+_vm._s(_vm.dailyReservationsThemeSettings.themeSettings .welcomeMessage)+" ")])]),_c('b-col',{staticClass:"col-4 mt-3 pr-4",attrs:{"align":"right"}},[_c('div',{style:({
            paddingRight: '0.5em',
            fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
          })},[_c('h3',{style:({
              color:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
            })},[_vm._v(" "+_vm._s(_vm.todayDate)+" ")]),_c('p',{style:({
              color:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: ((_vm.dailyReservationsThemeSettings
                .themeSettings.fontSize / 4) + "px")
            })},[_vm._v(" "+_vm._s(("#" + _vm.token))+" ")])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }