var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container ml-4 mr-4",class:{ 'is-approved': _vm.isApproved },style:({
      paddingBottom: _vm.footerHeight
    })},[_c('div',[_c('table',[_c('thead',{attrs:{"id":"table-header"}},[_c('tr',[_c('th',{staticClass:"table-cell-v-paddings",style:({
                color:
                  _vm.dailyReservationsThemeSettings.themeSettings
                    .titleTextColor,
                fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
                paddingLeft: '0.5em'
              })},[_vm._v(" "+_vm._s(_vm.showDesks ? _vm.$t('nameOrSubject') : _vm.$t('reservationSubject'))+" ")]),_c('th',{staticClass:"table-cell-v-paddings",style:({
                color:
                  _vm.dailyReservationsThemeSettings.themeSettings
                    .titleTextColor,
                fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
                width: '30%',
                marginLeft: '20px'
              })},[_vm._v(" "+_vm._s(_vm.$t('location'))+" ")]),_c('th',{staticClass:"table-cell-v-paddings",style:({
                color:
                  _vm.dailyReservationsThemeSettings.themeSettings
                    .titleTextColor,
                fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
                width: '12%'
              })},[_vm._v(" "+_vm._s(_vm.$t('Time'))+" ")])])]),_c('tbody',[_vm._l((_vm.reservations),function(reservation,index){return [_c('tr',{key:index,staticClass:"todayRow",style:({
                backgroundColor:
                  _vm.dailyReservationsThemeSettings.themeSettings
                    .tableColor,
                color:
                  _vm.dailyReservationsThemeSettings.themeSettings
                    .regularTextColor,
                fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
              })},[_c('td',{staticClass:"table-cell-v-paddings",style:({ 'padding-left': '0.5em' })},[_vm._v(" "+_vm._s(reservation.objectType === 'ROOM' ? reservation.reservationSubject : '')+" "+_vm._s(reservation.objectType === 'DESK' ? ((reservation.context.lastName) + ", " + (reservation.context.firstName)) : '')+" ")]),_c('td',{staticClass:"table-cell-v-paddings"},[_vm._v(" "+_vm._s(((reservation.objectType === 'ROOM' ? reservation.parentObjectName.trim() : reservation.secondParentObjectName.trim()) + ", " + (reservation.objectType === 'ROOM' ? reservation.objectName : reservation.parentObjectName) + " "))+" ")]),_c('td',{staticClass:"time-cell table-cell-v-paddings"},[(reservation.fixed)?[_vm._v(" "+_vm._s(_vm.$t('employeeTitle'))+" ")]:[_vm._v(" "+_vm._s(_vm.showTime(reservation.startDatetime))+" - "+_vm._s(_vm.showTime(reservation.endDatetime))+" ")]],2)])]}),(!_vm.reservations.length)?_c('tr',{staticClass:"todayRow",style:({
              backgroundColor:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .tableColor,
              color:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
            })},[_c('td',{style:({ 'padding-left': '0.5em' }),attrs:{"data-label":_vm.$t('noRecordsFound') + ':'}},[_vm._v(" "+_vm._s(_vm.$t('noRecordsFound'))+" ")]),_c('td'),_c('td')]):_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }