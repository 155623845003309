<template>
    <div
        class="table-container ml-4 mr-4"
        :class="{ 'is-approved': isApproved }"
        :style="{
          paddingBottom: footerHeight
        }"
      >
        <div>
          <table>
            <thead id="table-header">
              <tr>
                <th
                  class="table-cell-v-paddings"
                  :style="{
                    color:
                      dailyReservationsThemeSettings.themeSettings
                        .titleTextColor,
                    fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`,
                    paddingLeft: '0.5em'
                  }"
                >
                  {{
                    showDesks
                      ? $t('nameOrSubject')
                      : $t('reservationSubject')
                  }}
                </th>
                <th
                  class="table-cell-v-paddings"
                  :style="{
                    color:
                      dailyReservationsThemeSettings.themeSettings
                        .titleTextColor,
                    fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`,
                    width: '30%',
                    marginLeft: '20px'
                  }"
                >
                  {{ $t('location') }}
                </th>
                <th
                  class="table-cell-v-paddings"
                  :style="{
                    color:
                      dailyReservationsThemeSettings.themeSettings
                        .titleTextColor,
                    fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`,
                    width: '12%'
                  }"
                >
                  {{ $t('Time') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(reservation, index) in reservations">
                <tr
                  :key="index"
                  class="todayRow"
                  :style="{
                    backgroundColor:
                      dailyReservationsThemeSettings.themeSettings
                        .tableColor,
                    color:
                      dailyReservationsThemeSettings.themeSettings
                        .regularTextColor,
                    fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`
                  }"
                >
                  <td
                    :style="{ 'padding-left': '0.5em' }"
                    class="table-cell-v-paddings"
                  >
                    {{
                      reservation.objectType === 'ROOM'
                        ? reservation.reservationSubject
                        : ''
                    }}
                    {{
                      reservation.objectType === 'DESK'
                        ? `${reservation.context.lastName}, ${reservation.context.firstName}`
                        : ''
                    }}
                  </td>
                  <td class="table-cell-v-paddings">
                    {{
                      `${
                        reservation.objectType === 'ROOM'
                          ? reservation.parentObjectName.trim()
                          : reservation.secondParentObjectName.trim()
                      },
                        ${
                          reservation.objectType === 'ROOM'
                            ? reservation.objectName
                            : reservation.parentObjectName
                        }
                        `
                    }}
                  </td>
                  <td class="time-cell table-cell-v-paddings">
                    <template v-if="reservation.fixed">
                      {{ $t('employeeTitle') }}
                    </template>
                    <template v-else>
                      {{ showTime(reservation.startDatetime) }} -
                      {{ showTime(reservation.endDatetime) }}
                    </template> 
                  </td>
                </tr>
              </template>

              <tr
                v-if="!reservations.length"
                class="todayRow"
                :style="{
                  backgroundColor:
                    dailyReservationsThemeSettings.themeSettings
                      .tableColor,
                  color:
                    dailyReservationsThemeSettings.themeSettings
                      .regularTextColor,
                  fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`
                }"
              >
                <td
                  :data-label="$t('noRecordsFound') + ':'"
                  :style="{ 'padding-left': '0.5em' }"
                >
                  {{ $t('noRecordsFound') }}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'TodayReservationTable',
  props: {
    reservations: {
      type: Array,
      required: true
    },
    showDesks: {
      type: Boolean,
      required: true
    },
    dailyReservationsThemeSettings: {
      type: Object,
      required: true
    },
    isApproved: {
      type: Boolean,
      required: true
    },
    footerHeight: {
    type: [String, Number],
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    showTime(date) {
      return moment(date).format('HH:mm')
    },
  }
}
</script>

<style lang="scss" scoped>
.table-style {
  width: 100%;
  margin-left: 0%;
}
.tabe-responsive {
  & th {
    background-color: unset;
  }
}
table {
  box-shadow: none;
  background: transparent;
  border-collapse: separate;
  border-spacing: 0 15px;
  padding-right: 0em;
  padding-left: 0em;
}

table tbody tr td {
  background: transparent;
  border-radius: none;
}
tr.todayRow > td {
  padding-right: 1em;
  vertical-align: top;
  border-bottom: 2 px solid white;
}
.table-container {
  visibility: hidden;
  &.is-approved {
    visibility: visible;
  }
}

.table-cell-v-paddings {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.time-cell {
  white-space: nowrap;
}
</style>