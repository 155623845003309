var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center flex-wrap p-4",staticStyle:{"gap":"16px"}},[_vm._l((_vm.reservations),function(reservation){return _c('div',{staticClass:"reservation-card shadow",style:({
        backgroundColor: _vm.dailyReservationsThemeSettings.themeSettings
            .tableColor,
        fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
        width: (_vm.cardWidth + "px"),
        height: (_vm.cardHeight + "px"),
    })},[_c('div',{staticClass:"subject-info"},[_vm._v(" "+_vm._s(reservation.objectType === 'ROOM' ? reservation.reservationSubject : '')+" "+_vm._s(reservation.objectType === 'DESK' ? ((reservation.context.lastName) + ", " + (reservation.context.firstName)) : '')+" ")]),_c('div',{staticClass:"time-info"},[_c('font-awesome-icon',{attrs:{"icon":_vm.faClock}}),(reservation.fixed)?[_vm._v(" "+_vm._s(_vm.$t('allDay'))+" ")]:[_vm._v(" "+_vm._s(_vm.showTime(reservation.startDatetime))+" - "+_vm._s(_vm.showTime(reservation.endDatetime))+" ")]],2),_c('div',{staticClass:"location-info",class:{ 'text-smaller': _vm.locationLabel(reservation).length > 30 }},[_c('font-awesome-icon',{attrs:{"icon":_vm.faBuilding}}),_vm._v(" "+_vm._s(_vm.locationLabel(reservation))+" ")],1)])}),(!_vm.reservations.length)?_c('div',{staticClass:"reservation-card shadow",style:({
        backgroundColor: _vm.dailyReservationsThemeSettings.themeSettings
            .tableColor,
        fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
        width: (_vm.cardWidth + "px"),
        height: (_vm.cardHeight + "px"),
    })},[_vm._v(" "+_vm._s(_vm.$t('noRecordsFound'))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }