<template>
  <div
    :key="renderKey"
    :style="{
      backgroundColor: isApproved
        ? dailyReservationsThemeSettings.themeSettings.backgroundColor
        : '#ffffff',
      height: '100%',
      overflow: 'hidden auto'
    }"
  >
    <div
      ref="header"
      class="main-header"
      :style="{
        backgroundColor:
          dailyReservationsThemeSettings.themeSettings.titleBarColor
      }"
    >
      <b-row
        align="center"
        style="width:100%;margin:0;align-items: center; padding-left:1em;"
      >
        <b-col class="col-3 text-left">
          <img
            class="img-fluid"
            :src="dailyReservationsThemeSettings.logoUrl"
            style="width:220px"
          />
        </b-col>
        <b-col v-if="totalPages > 1" class="col-9 text-left">
          <div class="d-flex justify-content-end align-items-center">
            <p
              :style="{
                color:
                  dailyReservationsThemeSettings.themeSettings
                    .regularTextColor,
                fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`,
                paddingRight: '1em'
              }"
            >
              {{ $t('page') }}
            </p>
            <Pagination
              :current-page="currentPage"
              :total-pages="totalPages"
              :active-item-color="
                dailyReservationsThemeSettings.themeSettings
                  .regularTextColor
              "
              disable-arrows
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <div>
      <div
        v-if="!isApproved"
        class="page-container"
        :style="{
          paddingTop: '100px',
          paddingBottom: footerHeight
        }"
      >
        <b-container class="mt-4" align="center">
          <b-row align="center">
            <b-col class="col-3"></b-col>
            <b-col class="col-6">
              <div v-if="token">
                <label
                  :style="{
                    fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`
                  }"
                  >{{ $t('tokenGenerated') }}</label
                >
                <h1>{{ token }}</h1>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <TodayReservationsTable
        v-if="layout === 'table'"
        :reservations="reservations"
        :daily-reservations-theme-settings="dailyReservationsThemeSettings"
        :show-desks="showDesks"
        :is-approved="isApproved"
        :footer-height="footerHeight"
      />
      <TodayReservationCards
        v-if="layout === 'cards'"
        ref="reservationCards"
        :reservations="reservations"
        :show-desks="showDesks"
        :daily-reservations-theme-settings="dailyReservationsThemeSettings"
        :is-approved="isApproved"
        :footer-height="footerHeight"
        :header-height="headerHeight"
        @resize="(maxReservationsPerPage) => {perPage = maxReservationsPerPage}"
      />
    </div>
    <div
      ref="footer"
      class="footer main-footer"
      :style="{
        'background-color':
          dailyReservationsThemeSettings.themeSettings.titleBarColor
      }"
    >
      <b-row>
        <b-col class="col-8 mt-3 pl-4" align="left">
          <h3
            :style="{
              color:
                dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`,
              paddingLeft: '0.5em'
            }"
          >
            {{
              dailyReservationsThemeSettings.themeSettings
                .welcomeMessage
            }}
          </h3>
        </b-col>
        <b-col class="col-4 mt-3 pr-4" align="right">
          <div
            :style="{
              paddingRight: '0.5em',
              fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`
            }"
          >
            <h3
              :style="{
                color:
                  dailyReservationsThemeSettings.themeSettings
                    .regularTextColor,
                fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`
              }"
            >
              {{ todayDate }}
            </h3>
            <p
              :style="{
                color:
                  dailyReservationsThemeSettings.themeSettings
                    .regularTextColor,
                fontSize: `${dailyReservationsThemeSettings
                  .themeSettings.fontSize / 4}px`
              }"
            >
              {{ `#${token}` }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import i18n from '@/plugins/i18n'
import { mapState, mapActions } from 'vuex'
import { isEqual } from 'lodash'
import moment from 'moment-timezone'
import Vue from 'vue'
import vueMoment from 'vue-moment'
import OkkuApi from '@/services/OkkuApi'
import createRandomToken from '@/utils/createRandomToken'
import runWithTimeout from '@/utils/runWithTimeout'
import Pagination from '@/components/common/Pagination'
import TodayReservationsTable from '@/components/common/TodayReservationTable'
import TodayReservationCards from '@/components/common/TodayReservationCards'

Vue.use(vueMoment, { moment })

export default {
  name: 'TodayReservationsPage',
  components: {
    Pagination,
    TodayReservationsTable,
    TodayReservationCards,
  },
  data() {
    return {
      perPage: 1,
      currentPage: 1,
      validLanguages: ['en', 'nl', 'es', 'fr', 'de'],
      locals: {
        en: 'English',
        nl: 'Dutch',
        es: 'Spanish',
        fr: 'French',
        de: 'German'
      },
      reservations: [],
      token: null,
      isApproved: false,
      timeZone: '',
      org: '',
      orgPath: '',
      loading: true,
      language: this.$route.query.lang
        ? this.$route.query.lang
        : 'en',
      renderKey: false,
      todayScreenFloors: [],
      showDesks: false,
      totalCount: 0,
      timeoutReservations: 60000, // 1 minute
      timeoutCarousel: 10000, // 10 seconds
      headerHeight: '100px',
      footerHeight: '100px',
      savedDate: new Date(),
    }
  },
  computed: {
    ...mapState('publicScreens', ['dailyReservationsThemeSettings']),
    todayDate() {
      const options = {
        weekday: 'long',
        month: 'short',
        day: 'numeric'
      }

      return this.savedDate.toLocaleDateString(this.language, options)
    },

    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },

    layout() {
      return this.dailyReservationsThemeSettings.themeSettings.layout || 'table'
    }
  },
  updated() {
    this.$nextTick(() => {
      this.calcPageSize()
    })
  },

  async mounted() {
    this.parseDataFromRoute()
    await this.createAndSaveDateTimeToken()

    this.setDailyReservationsThemeSettings({
      org: this.org,
      orgPath: this.orgPath
    })
    this.changeLocale()

    this.$nextTick(() => {
      this.calcPageSize()
      const interval = 5000 // 5 seconds

      runWithTimeout({
        condition: this.checkForTokenApproval,
        callback: () => {
          this.runRepeatedly(
            this.checkForTokenApproval,
            this.timeoutReservations
          )
          this.runRepeatedly(
            this.handlePageChange,
            this.timeoutCarousel
          )
        },
        maxAttempts: 3600,
        interval
      })
    })
  },
  methods: {
    ...mapActions('publicScreens', [
      'setDailyReservationsThemeSettings'
    ]),

    checkIsSameDay() {
      const now = moment(new Date())
      return moment(this.savedDate).isSame(now, 'day')
    },

    reloadPage() {
      if (navigator.onLine) {
        const url = window.location.href
        const urlObj = new URL(url)
        urlObj.searchParams.set('_', new Date().getTime())
        window.location.href = urlObj.toString()
      } else {
        setTimeout(() => this.reloadPage(), this.timeoutReservations)
      }
    },

    calcPageSize() {
      this.headerHeight = this.$refs.header.offsetHeight
      this.footerHeight = this.$refs.footer.offsetHeight
      let resultsPerPage = 1
      if (this.layout === 'table') {
        const verticalBorderSpacing = 15
        const tableHeaderFullHeight = (document.getElementById('table-header')?.offsetHeight || 50) + verticalBorderSpacing
        console.log(tableHeaderFullHeight, 'tableHeaderFullHeight', verticalBorderSpacing, document.getElementById('table-header')?.offsetHeight)
        const availableHeight = window.innerHeight - this.footerHeight - this.headerHeight - tableHeaderFullHeight
        resultsPerPage = Math.floor(availableHeight / tableHeaderFullHeight)
        this.perPage = resultsPerPage > 1 ? resultsPerPage : 1
      }
    },

    parseDataFromRoute() {
      this.org = this.$route.params.org
      this.orgPath = this.$route.params.orgPath
      this.showDesks = this.$route.query.desks === 'true'
      const { floors } = this.$route.query
      this.todayScreenFloors = Array.isArray(floors)
        ? floors
        : [floors || null]
    },

    async runRepeatedly(func, interval) {
      const checkAndContinue = async () => {
        await func()
        setTimeout(checkAndContinue, interval)
      }
      checkAndContinue()
    },

    async createAndSaveDateTimeToken() {
      const storage = JSON.parse(
        localStorage.getItem('dailyReservationToken')
      )

      if (
        !storage ||
        storage.org !== this.org ||
        storage.orgPath !== this.orgPath ||
        !isEqual(storage.floors, this.todayScreenFloors) ||
        storage.showDesks !== this.showDesks
      ) {
        this.token = createRandomToken()
        await OkkuApi.SaveDateTimeToken(
          this.token,
          this.org,
          this.orgPath,
          this.todayScreenFloors
        )
        localStorage.setItem(
          'dailyReservationToken',
          JSON.stringify({
            org: this.org,
            orgPath: this.orgPath,
            token: this.token,
            approved: false,
            floors: this.todayScreenFloors,
            showDesks: this.showDesks
          })
        )
      } else {
        this.token = storage.token
      }
    },

    async checkForTokenApproval() {
      try {
        if (!this.checkIsSameDay()) this.reloadPage()
        const storage = JSON.parse(
          localStorage.getItem('dailyReservationToken')
        )
        const {
          data: { status, modificationDateTime, rejected }
        } = await OkkuApi.checkForTokenApproval(
          storage.token,
          this.org,
          this.orgPath
        )

        if (rejected) {
          // Token is rejected
          this.isApproved = false
          localStorage.removeItem('dailyReservationToken')
          this.createAndSaveDateTimeToken()
          this.loading = false
          return false
        }
        if (status && modificationDateTime) {
          // Token is approved
          if (this.totalPages <= 1) {
            await this.getReservations()
          }
          this.isApproved = status
          this.loading = false
          return true
        }
        return false
      } catch (error) {
        console.error('Error checking for token approval:', error)
        setTimeout(
          () => this.checkForTokenApproval(),
          this.timeoutReservations
        )
        return false
      }
    },

    async handlePageChange() {
      if (this.totalPages <= 1) return

      if (this.currentPage < this.totalPages) {
        this.currentPage += 1
      } else {
        this.currentPage = 1
      }
      await this.getReservations()
    },

    async getReservations() {
      try {
        const {
          data: { reservations, totalCount }
        } = await OkkuApi.getDailyScreenReservations(
          this.org,
          this.orgPath,
          this.todayScreenFloors,
          this.showDesks,
          this.currentPage,
          this.perPage
        )

        console.log(`Reservations amount: ${reservations.length}`)

        if (reservations.length === 0) {
          setTimeout(
            () => this.reloadPage(),
            this.timeoutReservations
          )
        }
        this.reservations = [...reservations]
        this.totalCount = totalCount
      } catch (error) {
        this.reservations = []
        this.totalCount = 0
        console.error('Error fetching reservations:', error)
        setTimeout(
          () => this.getReservations(),
          this.timeoutReservations
        )
      }
    },

    changeLocale() {
      let lang = ''
      if (this.validLanguages.includes(this.language)) {
        lang = this.language
      } else {
        lang = 'en'
      }
      i18n.locale = lang
      moment.locale(this.$i18n.locale)
      this.$cookie.set('lang', i18n.locale, 30)
      this.currentLang = this.locals[this.language]
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  width: 100px;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 0;
}
.spinner-border {
  display: none;
}

.main-footer {
  position: fixed;
  min-height: 100px;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.main-header {
  min-height: 100px;
  // position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px;
}
</style>
