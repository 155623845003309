import {
  SET_PUBLIC_SCREENS_TOKENS,
  SET_DAILY_RESERVATIONS_THEME_SETTINGS,
  RESET_MODULE_STATE
} from './mutationTypes'
import { getDefaultState } from './state'

const mutations = {
  [SET_PUBLIC_SCREENS_TOKENS](state, payload) {
    state.publicScreensTokens = payload
  },

  [SET_DAILY_RESERVATIONS_THEME_SETTINGS](state, payload) {
    state.dailyReservationsThemeSettings = {
      themeSettings: {
        backgroundColor: payload.themeSettings.background_color,
        regularTextColor: payload.themeSettings.regular_text_color,
        tableColor: payload.themeSettings.table_color,
        titleBarColor: payload.themeSettings.title_bar_color,
        titleTextColor: payload.themeSettings.title_text_color,
        welcomeMessage: payload.themeSettings.welcome_message,
        fontSize: payload.themeSettings.font_size,
        layout: payload.themeSettings.layout || 'table',
        cardWidth: payload.themeSettings.card_width,
        cardHeight: payload.themeSettings.card_height,
      },
      logoUrl: payload.logoUrl
    }
  },

  [RESET_MODULE_STATE](state) {
    Object.assign(state, getDefaultState())
  }
}

export default mutations
