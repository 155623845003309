<template>
    <div class="d-flex justify-content-center flex-wrap p-4" style="gap: 16px;">
        <div v-for="reservation in reservations" class="reservation-card shadow" :style="{
            backgroundColor: dailyReservationsThemeSettings.themeSettings
                .tableColor,
            fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`,
            width: `${cardWidth}px`,
            height: `${cardHeight}px`,
        }">
            <div class="subject-info">
                {{
                    reservation.objectType === 'ROOM'
                        ? reservation.reservationSubject
                        : ''
                }}
                {{
                    reservation.objectType === 'DESK'
                        ? `${reservation.context.lastName}, ${reservation.context.firstName}`
                        : ''
                }}
            </div>
            <div class="time-info">
                <font-awesome-icon :icon="faClock" />
                <template v-if="reservation.fixed">
                    {{ $t('allDay') }}
                </template>
                <template v-else>
                    {{ showTime(reservation.startDatetime) }} -
                    {{ showTime(reservation.endDatetime) }}
                </template>
            </div>
            <div class="location-info" :class="{ 'text-smaller': locationLabel(reservation).length > 30 }">
                <font-awesome-icon :icon="faBuilding" /> {{ locationLabel(reservation) }}
            </div>
        </div>
        <div v-if="!reservations.length" class="reservation-card shadow" :style="{
            backgroundColor: dailyReservationsThemeSettings.themeSettings
                .tableColor,
            fontSize: `${dailyReservationsThemeSettings.themeSettings.fontSize}px`,
            width: `${cardWidth}px`,
            height: `${cardHeight}px`,
        }">
            {{ $t('noRecordsFound') }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment';
import { faClock, faBuilding } from '@fortawesome/free-regular-svg-icons'

export default {
    name: 'TodayReservationCards',
    props: {
        reservations: {
            type: Array,
            required: true
        },
        showDesks: {
            type: Boolean,
            required: true
        },
        isApproved: {
            type: Boolean,
            required: true
        },
        footerHeight: {
            type: [String, Number],
            required: true
        },
        headerHeight: {
            type: [String, Number],
            required: true
        },
    },
    data() {
        return {
            faClock,
            faBuilding,
        };
    },
    computed: {
        ...mapState('publicScreens', ['dailyReservationsThemeSettings']),
        cardWidth() {
            return this.dailyReservationsThemeSettings.themeSettings.cardWidth || 200
        },
        cardHeight() {
            return this.dailyReservationsThemeSettings.themeSettings.cardHeight || 250
        },
        maxCardsPerRow() {
            return Math.floor((window.innerWidth - 48) / (parseInt(this.cardWidth, 10) + 16));
        },
        maxCardRows() {
            const remainingHeight = window.innerHeight - parseInt(this.headerHeight, 10) - parseInt(this.footerHeight, 10) - 48;
            return Math.max(1, Math.floor(remainingHeight / (parseInt(this.cardHeight, 10) + 16)));
        },
        maxReservations() {
            return this.maxCardsPerRow * this.maxCardRows;
        },
    },
    watch: {
        maxReservations: {
            immediate: true,
            handler(newVal) {
                this.$emit('resize', newVal);
            }
        }
    },
    methods: {
        showTime(date) {
            return moment(date).format('HH:mm')
        },
        locationLabel(reservation) {
            if (reservation.objectType === 'ROOM') {
                return `${reservation.parentObjectName.trim()}, ${reservation.objectName.trim()}`;
            }
            return `${reservation.secondParentObjectName.trim()}, ${reservation.parentObjectName.trim()}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.reservation-card {
    overflow: hidden;
    padding: 1rem;
    border-radius: 10px;
}

.subject-info {
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.time-info {
    font-size: smaller;
}

.text-smaller {
    font-size: smaller;
}

.location-info {
    width: 100%;
    overflow: hidden;
}
</style>